.link {
  text-decoration: none;
}

.wrapper {
  height: 100%;
  overflow: hidden;
  border-radius: 0.3rem;
  box-shadow: 0 0.03rem 0.06rem rgba(0, 0, 0, 0.1),
    0 0.1rem 0.3rem rgba(0, 0, 0, 0.1);
  transition-duration: 0.3s;
  position: relative;

  .contentContainer {
    padding: 9% 11% 11% 11% !important;
    align-items: flex-start;

    h2 > a {
      color: black;
      font-size: 1.4rem !important;
      font-weight: 700 !important;
    }

    @media (max-width: 599px) {
      h2 > a {
        color: black;
        font-size: 1.2rem !important;
        font-weight: 700 !important;
      }
    }

    time {
      color: #bbbbbb !important;
      font-size: 0.9rem !important;
    }
  }

  .imageContainer {
    img {
      display: block;
      width: 100%;
      transition-duration: 0.3s;
    }
  }
}

.wrapper:hover {
  box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.1),
    0 0.33rem 1rem rgba(0, 0, 0, 0.15);

  img {
    transform: scale(1.2);
    transition-duration: 0.3s;
  }
}

.categoryButton {
  font-weight: 700 !important;
  text-transform: uppercase !important;
  font-size: 10px !important;
  position: absolute !important;
  top: 1.2rem !important;
  right: 1.2rem !important;

  display: flex;
  flex-wrap: wrap;
  align-items: center;

  a {
    margin-top: 0 !important;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    line-height: 1.2 !important;
    font-weight: 400;
    font-style: normal;
    text-transform: none;
    letter-spacing: 0em;

    padding: 0.5em 0.5em;
    background: #ffffff;
    border-color: #daa48a;
    border-style: solid;
    border-radius: 0.3em;

    color: #daa48a !important;
    box-shadow: 0 0em 0em rgba(0, 0, 0, 0.2);
    display: inline-block;
    vertical-align: top;
    text-align: center;
    line-height: 1.2;
    position: relative;
    transition-property: background, border, box-shadow, color, opacity,
      transform;
    transition-duration: 0.3s;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  a:hover {
    box-shadow: 0 0em 0em rgba(0, 0, 0, 0.2);
    background: #81d742;
    border-color: #81d742;
    color: #ffffff !important;
  }
}
