@import '../../../styles/variables.scss';

.socialBar {
  margin-left: 1em;
  line-height: 2;
  display: flex;
  margin-left: auto;
  @media (max-width: $size-hide-social) {
    display: none;
  }
}

.item {
  line-height: 2em;
  margin: 5px;
  text-align: center;
  border-radius: 50%;
  width: 2em;
}

.item > a {
  color: $color-grey;
  background-color: #ffffff00;
}

.item:hover {
  transition: background-color 300ms linear, color 300ms linear;
}

.item.facebook:hover {
  background-color: $color-fb;
  > a {
    color: $color-white;
  }
}

.item.twitter:hover {
  background: $color-twitter;
  > a {
    color: $color-white;
  }
}

.item.instagram:hover {
  background: $color-instagram;
  > a {
    color: $color-white;
  }
}

.item.pinterest:hover {
  background: $color-pinterest;
  > a {
    color: $color-white;
  }
}
