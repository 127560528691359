@import '../../styles/variables.scss';

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: inherit;
  line-height: 1.4;
  margin: 0 0 1.5rem;
  padding-top: 1.5rem;
}

.page {
  padding-top: 20px;
  padding-bottom: 48px;
}

.main {
  max-width: $size-large;
  margin: 0 auto;
  width: 100%;
  flex-grow: 1;
}

.content {
  z-index: 1;
  position: relative;
  min-height: 600px;
  margin: 0 auto;
  color: $color-dark-grey;
  border-color: #e8e5e1;
  background-color: $color-white;
  margin-top: 77px;
  @media (max-width: $size-burger-menu) {
    margin-top: 57px;
  }
}

.separator {
  border-bottom: 1px solid;
  height: 1.5em;
  width: 100%;
  margin-bottom: 1.5em;
  color: #e8e5e1;
}

.withAdminBar {
  margin-top: 130px;
  @media (max-width: $size-burger-menu) {
    margin-top: 100px;
  }
}

.container {
  padding: 40px;
}

@media (max-width: 767px) {
  h1 {
    font-size: 2em;
  }

  h2 {
    font-size: 1.4em;
  }

  h3 {
    font-size: 1.1em;
  }
}

.inlineLink {
  text-decoration: none;
  color: #1471b7;
}

.bigH1 {
  text-align: left;
  font-family: Lato;
  font-weight: 700;
  font-style: normal;
  color: #282828 !important;
  font-size: 3rem !important;
  line-height: 1.2 !important;

  letter-spacing: 0;
  margin-bottom: 0px;
  margin: 0 0 1.5rem;
  padding-top: 1.5rem;
}

a {
  color: $color-blue;
  text-decoration: none;
}

h1 {
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  font-size: 2rem;
  line-height: 1.2;
  letter-spacing: 0;
  margin-bottom: 0px;
}

h2 {
  font-family: 'Lato', sans-serif;
  font-weight: 500;
  font-size: 1.6em;
  line-height: 1.2;
  letter-spacing: 0;
  margin-bottom: 0px;
  padding-top: 0em !important;
  padding-bottom: 0.75em !important;
  margin: 0;
}

h2:last-child {
  padding-bottom: 0.5em !important;
  margin-bottom: 0;
}

h3 {
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  font-size: 1.2em;
  line-height: 1.2;
  letter-spacing: 0;
  margin-bottom: 0px;
  padding-top: 0em !important;
  padding-bottom: 0.5em !important;
}

h4 {
  font-weight: 400;
  font-size: 20px;
  line-height: 1.2;
  letter-spacing: 0;
  margin: 0 0 1.5rem;
}

sup {
  vertical-align: top;
  font-size: 0.6em;
}

p {
  margin: 0;
}

i {
  font-style: normal;
}

.spacer {
  height: 30px;
  clear: both;
  display: block;
}

.p-checkbox {
  border-color: $color-white;
}

.p-checkbox-box.p-highlight {
  background-color: $color-green !important;
}

.p-multiselect-item.p-highlight {
  background: initial !important;
}

.p-multiselect-item:hover {
  background: $color-orange-50 !important;
}

.p-multiselect:hover {
  border-color: #ced4da !important;
  box-shadow: none !important;
  background-color: $color-orange-10 !important;
}

.p-multiselect .p-multiselect-label {
  padding: 0.25rem 0.5rem !important;
}

.p-grid {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0 -1.5rem;
}

[class*='col-'] {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.col-inner {
  padding: 0;
}

.grid-margin {
  margin: 0;
}

@media screen and (max-width: 992px) {
  [class*='col-'] {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .p-grid {
    margin: 0 !important;
  }
}
