.articleBreadcrumbs {
  line-height: 1.4;
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: left;

  a {
    outline: none !important;
    text-decoration: none;
    background: none;
    transition-property: background, box-shadow, border, color, opacity,
      transform;
    transition-duration: 0.3s;
    -webkit-text-decoration-skip: objects;
  }

  a:hover {
    color: #423b38;
    border-bottom: 1px solid;
  }

  li {
    margin: 0 0 0.5rem;
  }

  .breadcrumbsItem {
    display: inline-block;
    vertical-align: top;
    margin-bottom: 0;
  }

  .breadcrumbs-separator {
    display: inline-block;
    vertical-align: top;
    margin-bottom: 0;
    margin: 0 0.6em;
  }

  .arrow {
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
  }
}
