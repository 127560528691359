.articleContents {
  .IV {
    width: 100%;
  }

  .IVT {
    font-size: 1.2rem;
    border-bottom: 2px solid #e9e9e9;
    padding-bottom: 6px;
  }

  .IVH1 {
    font-weight: 600;
    padding-top: 9px;
  }

  .IVH2 {
    padding-left: 30px;
  }

  a {
    text-decoration: none;
    background: none;
    transition-property: background, box-shadow, border, color, opacity,
      transform;
    transition-duration: 0.3s;
  }

  a:hover {
    color: #423b38;
  }

  @media only screen and (min-width: 1100px) {
    .IV {
      background: #fafafa;
      width: 60%;
      border-radius: 5px;
      margin-left: 20px;
      padding-left: 30px;
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }
}
