.wrapper {
  margin: 0 0 0 0 !important;
  background: none;
  width: 100%;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;

  a {
    text-decoration: none;
    color: #ffffff;
  }

  .button {
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    line-height: 1.4 !important;
    font-weight: 400;
    font-style: normal;
    text-transform: uppercase;
    letter-spacing: 0.07em;
    border-radius: 0.3em;
    padding: 0.8em 1.8em;
    background: linear-gradient(0deg, #daa48a, #f2bda2);
    border-color: #dba68c;
    color: #ffffff !important;
    box-shadow: 0 0.15em 0.3em rgba(0, 0, 0, 0.2);
    text-align: center;
  }

  .button:hover {
    box-shadow: 0 0em 0em rgba(0, 0, 0, 0.2);
    background: #81d742;
  }

  span {
    position: relative;
    z-index: 1;
  }
}
