.article {
  padding-top: 3rem;

  @media (max-width: 991px) {
    .p-grid {
      display: inherit;
    }
  }

  .firstHeader {
    padding-top: 0em !important;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    margin: 0 0 1.5rem;
  }

  .article-content {
    padding-right: 2.5rem;

    h3 {
      padding-top: 7px !important;
    }
  }

  @media (max-width: 991px) {
    .article-content {
      width: 100%;
    }
  }

  table:last-child {
    margin-bottom: 0;
  }

  td {
    vertical-align: top;
    padding: 0.6rem 1rem;
    border-bottom: 1px solid;
    outline: none !important;
    border-color: #e8e5e1;
  }

  .list-wrapper {
    ul {
      margin: 8px 0 0 0;
      list-style-type: disc;
      margin-left: 2.5rem;
      padding: 0;
    }
    li {
      margin: 0 0 0.5rem;
    }
  }

  @media (max-width: 991px) {
    .sidebar {
      width: 100%;
      padding-top: 4rem !important;
    }
  }

  .sidebar {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: calc(4rem - 48px);

    h3 {
      font-weight: 400;
      font-size: 20px;
      line-height: 1.2;
      letter-spacing: 0;
    }

    h3.widgetTitle {
      margin-bottom: 1rem;
    }

    .cardGrid {
      margin-bottom: 3rem;
    }

    .moreInfo {
      margin-bottom: 3rem;
    }

    .socialMedia {
      .socialsList {
        margin: -0.1em;
      }

      .socialsItem {
        display: inline-block;
        vertical-align: top;
        position: relative;
        padding: 0.1em;

        .socialsItem-link {
          background: #f2f2f0;
          display: block;
          text-align: center;
          position: relative;
          overflow: hidden;
          line-height: 2.5em;
          width: 2.5em;
          border: none !important;
          border-radius: inherit;
          z-index: 0;
        }
      }

      .socialsItem.twitter > a {
        color: #55acee;
      }

      .socialsItem.instagram > a {
        color: inherit;
      }

      .socialsItemHover {
        display: block;
      }

      .socialsItem:hover .socialsItemHover {
        color: #fff !important;
      }

      .socialsItem.facebook:hover .socialsItemHover {
        background: #1e77f0;
      }

      .socialsItem.twitter:hover .socialsItemHover {
        background: #55acee;
      }

      .socialsItem.pinterest:hover .socialsItemHover {
        background: #e60023;
      }

      .socialsItem.instagram:hover .socialsItemHover {
        background: radial-gradient(
            circle farthest-corner at 35% 100%,
            #fec564,
            transparent 50%
          ),
          radial-gradient(
            circle farthest-corner at 10% 140%,
            #feda7e,
            transparent 50%
          ),
          radial-gradient(
            ellipse farthest-corner at 0 -25%,
            #5258cf,
            transparent 50%
          ),
          radial-gradient(
            ellipse farthest-corner at 20% -50%,
            #5258cf,
            transparent 50%
          ),
          radial-gradient(
            ellipse farthest-corner at 100% 0,
            #893dc2,
            transparent 50%
          ),
          radial-gradient(
            ellipse farthest-corner at 60% -20%,
            #893dc2,
            transparent 50%
          ),
          radial-gradient(
            ellipse farthest-corner at 100% 100%,
            #d9317a,
            transparent
          ),
          linear-gradient(
            #6559ca,
            #bc318f 30%,
            #e42e66 50%,
            #fa5332 70%,
            #ffdc80 100%
          );
      }

      .socialsItem.pinterest > a {
        color: #e60023;
      }
    }
  }

  .navItemArrow {
    margin-right: 5px;
  }
}
