@import './../../styles/variables.scss';

.preloader {
  background: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  z-index: 11111;
  overflow: hidden;
  transition: opacity 0.3s cubic-bezier(0.6, 0.3, 0.3, 0.6);
}

.preloader-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  background-color: inherit;
  color: inherit;
  opacity: 1;
  transition: opacity 0.15s;
  transform: translate3d(-50%, -50%, 0);
}

.preloader-inner {
  font-size: 4rem;
  color: $color-orange;
  display: inline-block;
  vertical-align: top;
  position: relative;
  width: 1em;
  height: 1em;
  border-color: currentColor !important;
}

.preloader-inner-circle {
  border: 0.5em solid;
  border-radius: 50%;
  animation: scale 1s infinite cubic-bezier(0.6, 0.3, 0.3, 0.6);
}

@keyframes scale {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
