@media screen and (min-width: 576px) {
  .blogCard {
    padding: 1.5rem !important;
  }
}

@media screen and (max-width: 575px) {
  .blogCard {
    padding-bottom: 1.5rem !important;
  }
}

.filterContainer {
  background: #f2f2f0;

  .filter-list {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;

    .filter-list-item {
      white-space: nowrap;
      cursor: pointer;
      padding: 0 1.2rem;
      line-height: 3.5rem;
      border: none;
      transition-property: background, box-shadow, border, color, opacity,
        transform;
      transition-duration: 0.2s;

      span {
        font-size: 16px;
        font-weight: 400;
      }
    }

    @media screen and (max-width: 480px) {
      .filter-list-item {
        font-size: 0.9rem !important;
        text-align: center;
        margin: 0 !important;
        padding-left: 0.6rem !important;
        padding-right: 0.6rem !important;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 50%;
      }
    }

    .filter-list-item:hover {
      background: #daa48a;
      color: #fff;
    }

    .filter-list-item.active {
      cursor: default;
      background: #ffffff;
      color: #daa48a;
    }
  }
}
