.mainContent {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

@media (max-width: 992px) {
  .titleSpacer {
    display: none;
  }
}

@media (max-width: 690px) {
  .imageCol {
    display: none;
  }
}
