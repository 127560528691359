// colors
$color-red: #f00;
$color-orange-10: #edd7cc;
$color-orange: #daa48a;
$color-orange-50: #daa48aaa;
$color-blue: #1471b7;
$color-blue-border: #2196f3;
$color-light-green: #81d742;
$color-green: #11a911;
$color-dark-grey: #282828;
$color-grey: #474747;
$color-medium-grey: #dadada;
$color-light-grey: #f2f2f0;
$color-grey-form-border: #e8e5e1;
$color-grey-form-background: #f7f7f7;
$color-grey-font: #38505c;
$color-seperator: #4d4a49;
$color-white: #ffffff;

$color-yellow: #ffd700;

$color-fb: #1e77f0;
$color-instagram: radial-gradient(
    circle farthest-corner at 35% 100%,
    #fec564,
    transparent 50%
  ),
  radial-gradient(circle farthest-corner at 10% 140%, #feda7e, transparent 50%),
  radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%),
  radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%),
  radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%),
  radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%),
  radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent),
  linear-gradient(#6559ca, #bc318f 30%, #e42e66 50%, #fa5332 70%, #ffdc80 100%);
$color-twitter: #55acee;
$color-pinterest: #e60023;

// sizes
$size-burger-menu: 830px;
$single-column: 600px;
$size-large: 1200px;
$size-hide-social: 1070px;
