@import '../../../styles/variables.scss';

.footer {
  background-color: rgb(71, 71, 71);
  display: flex;
  align-items: center;
  position: relative;
  margin: 0 auto;
  height: inherit;
  box-shadow: 1px 0 0 rgba(0, 0, 0, 0.08);
  max-width: 1240px;
  color: #ffffff;
  min-height: 231px;
  flex-direction: column;
  z-index: 0;

  .smallSeparator {
    border-bottom: 1px solid;
    height: 16px;
    width: 100%;
    margin-bottom: 16px;
    color: $color-seperator;
  }

  .footerElement {
    padding-top: 2rem !important;
  }

  .miscContainer {
    padding-bottom: 2rem !important;
  }
}

.footerHeader {
  display: flex;
  width: 100%;
  h4 {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 20px;
    letter-spacing: 0;
    margin: 0;
  }
}

// single col layout
@media (max-width: $single-column) {
  .col {
    flex-basis: 100%;
  }
}

.footerSection {
  width: 100%;
  display: flex;
  @media (max-width: $single-column) {
    flex-wrap: wrap;
  }
}
.colWrapper {
  align-content: baseline;
  flex-wrap: wrap;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  @media (max-width: $single-column) {
    width: 100%;
  }
}

.vertical {
  flex-direction: column;
  padding-right: 20px;
}

.col {
  @media (min-width: $single-column + 1) {
    max-width: 235px;
    width: 235px;
    margin-right: 2.5rem;
  }
  flex-grow: 1;
}
.row {
  > a {
    color: #ffffff;
    text-decoration: none;
  }
}

.image {
  width: 100%;
  padding: 0 20px 0px 0px;
  @media (max-width: $single-column) {
    margin-bottom: 20px;
  }
}

.image:hover {
  filter: invert(0) sepia(1) saturate(20) hue-rotate(295.2deg) brightness(1.08);
}

.multiColumnDesc {
  font-size: 0.85rem;
  @media (max-width: $single-column) {
    display: none;
  }
}

.claim {
  color: $color-dark-grey;
  background-color: $color-light-grey;
  text-align: center;
  font-size: 12px;
  padding: 20px;
  width: calc(100% + 80px);
}
