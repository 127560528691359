@import '../../../styles/variables.scss';

.headerWrapper {
  max-width: $size-large;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 2;
}

.header {
  display: flex;
  align-items: center;
  position: relative;
  margin: 0 auto;
  height: inherit;
  line-height: 70px;
  background: $color-white;
  color: $color-grey;

  height: 70px;
  @media (max-width: $size-burger-menu) {
    height: 50px;
    flex-direction: column;
    padding: 10px 0px;
  }
}

.logoLink {
  display: flex;
  height: 100%;
  flex-grow: 0.1;
  @media (max-width: $size-burger-menu) {
    width: 100%;
    margin-left: 10px;
  }
}

.logo {
  height: auto;
  min-width: 250px;
  max-width: 100%;
}

.headerLinks {
  margin-left: 20px;
  display: flex;
  @media (max-width: $size-hide-social) {
    width: 100%;
  }
  @media (max-width: $size-burger-menu) {
    margin-top: 20px;
    margin-left: 0;
    width: 100%;
    transition: max-height 0.5s ease-out;
    max-height: 500px;
    flex-direction: column;
  }
}

.icon {
  margin-right: 5px;
}

.headerLink {
  display: flex;
  height: 100%;
  text-decoration: none;
  padding-left: 20px;
  padding-right: 20px;
  color: inherit;
  font-weight: 400;
  align-items: baseline;
  color: $color-grey;
  white-space: nowrap;
  background-color: $color-white;
  @media (max-width: $size-hide-social) {
    flex-grow: 1;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
  }
  @media (max-width: $size-burger-menu) {
    justify-content: left;
    padding-left: 20px;
  }
}

.headerLink:hover {
  text-decoration: none;
  color: $color-dark-grey;
  background-color: $color-light-grey;
}

.headerLink:active {
  transition: background-color 100ms linear;
  background-color: $color-medium-grey;
}

.line {
  height: 7px;
  width: 100%;
  background-color: $color-orange;
}

.burgerMenu {
  position: absolute;
  right: 5px;
  top: 10px;
  line-height: 0px;
  border: none;
  background-color: transparent;
  @media (min-width: $size-burger-menu - 1) {
    display: none;
  }
}
.burgerMenu:active,
.burgerMenu:focus {
  border: none;
  outline: none;
}
.hideMenu {
  @media (max-width: $size-burger-menu) {
    > .headerLinks {
      margin-top: 10px;
      overflow: hidden;
      max-height: 0px;
    }
  }
}

.navList {
  position: relative;
  list-style-type: none;
}

.navList:not(.navLevel1) {
  display: block;
  transform-origin: 50% 0;
  transform: scaleY(0) translateZ(0);
  opacity: 0;
  transition: transform 0.3s, opacity 0.3s;
  background: #f2f2f0;
  color: #282828;
  padding: 0;
}

.navLevel2 {
  padding-top: 0px;
  padding-bottom: 0px;
  text-align: left;
  position: absolute;
  left: 0;
  top: 70px;
  margin-top: 0;
  z-index: 120;
  min-width: 12rem;
}
.navLevel3 {
  position: absolute;
  left: 100%;
  top: 0;
  z-index: 121;
  min-width: 12rem;
}
.menuItem {
  display: block;
  position: relative;
  margin: 0;
}

.menuItem:hover > div > .navList {
  transform: scaleY(1) translateZ(0);
  opacity: 1;
}

.menuItem:hover > .menuItemAnchor {
  background: #474747;
  color: #ffffff;
}

.navItemArrow {
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  line-height: 2.7em;
  width: 2em;
  font-weight: normal;
  line-height: inherit;
  text-transform: none;
  text-align: center;
  transition: transform 0.3s;

  svg {
    height: 100%;
    font-size: 1.3em;
  }
}

.menuItemAnchor {
  color: inherit;
  display: block;
  line-height: 1.5em;
  padding: 0.6em 20px;
  white-space: nowrap;
  transition: none;
}
